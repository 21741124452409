import logo from "./resources/images/logo.svg";
import bigLogo from "./resources/images/big-logo.svg";
import EnterApp from "./resources/images/enter-app.png";
import "./App.css";

function App() {
  return (
    <main className="main">
      <div className="container">
        <header className="main-header">
          <div className="left-header">
            <img src={logo} alt="dizzy logo" className="logo" />
            <h2 className="brand">TTX.FINANCE</h2>
          </div>
          <a
            className="btn"
            href="https://app.ttx.finance/staking"
            target="_blank"
            rel="noreferrer"
          >
            <img src={EnterApp} alt="Enter app" />
          </a>
        </header>
        <div className="content">
          <img src={bigLogo} alt="dizzy logo" className="content-logo" />
          <h1 className="brand-title">TTX FINANCE </h1>
          <h1 className="title">Future of the DeFi is Here</h1>
          <p className="intro">
            Stake and farm your exclusive token here on this powerful platform
            and manage your rewards.
          </p>
          <div>
            <a
              className="btn-left"
              href="https://app.ttx.finance/staking"
              target="_blank"
              rel="noreferrer"
            >
              <img src={EnterApp} alt="Enter app" />
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
